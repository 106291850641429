import { useRef, useEffect } from 'react';

// types
import { TeaserTilesProps } from './tiles.types';
import { TitleFormats } from 'components/ContentElements/Title/title.types';

// components
import { Title } from 'components/ContentElements/Title/Title';
import TileRenderer from './tile-renderer';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { useReactKeys } from 'utils/hooks/use-react-keys';

// load grid plugin (only on client side)
let Isotope;
if (typeof window !== 'undefined') {
  Isotope = require('isotope-layout');
  require('isotope-packery');
}

export function Tiles({
  title,
  subtitle,
  tile,
  anchor,
  titleInAnchor,
  contentIndex,
  from,
  isSectionReference,
}: Readonly<TeaserTilesProps>) {
  const tilesWrapper = useRef<HTMLDivElement | null>(null);
  const iso = useRef<{
    destroy: () => void;
  } | null>(null);
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const tilesWithKey = useReactKeys(tile, ['type', 'previewId', 'headline', 'subheadline']);

  useEffect(() => {
    const timeout = setTimeout(() => {
      iso.current = new Isotope(tilesWrapper.current, {
        itemSelector: '.c-tile-homepage',
        layoutMode: 'packery',
        percentPosition: true,
        packery: {
          columnWidth: '.tile-sizer',
          rowHeight: '.tile-sizer',
        },
      });
    }, 200);

    return () => {
      iso.current?.destroy();
      clearTimeout(timeout);
    };
  });

  return (
    <section className="grid-container" id={sectionId}>
      <Title
        title={title}
        subtitle={subtitle}
        Format={TitleFormats.h2}
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
        isSectionReference={isSectionReference}
        formatClassName="h3"
      />
      <div
        className="c-tiles-homepage"
        ref={tilesWrapper}
        data-preview-id={
          from !== 'homepage' && !isSectionReference ? '#st_homepageTiles' : undefined
        }
      >
        <div className="tile-sizer" />
        {tilesWithKey?.map(({ key, ...item }, index) => (
          <TileRenderer key={key} index={index} from={from} {...item} />
        ))}
      </div>
    </section>
  );
}
