import { useRef, useEffect } from 'react';
import key from 'weak-key';

// utils
import { isEmpty } from 'utils/is-empty';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

export interface ToolIntegrationProps {
  identifier: string;
  scriptUrl: string;
  styleUrl: string;
  mode?: string;
}

export function ToolIntegration({
  identifier,
  scriptUrl,
  styleUrl,
  mode = 'embedded',
}: Readonly<ToolIntegrationProps>) {
  const currentLanguage = useCurrentLanguage();
  const toolId = useRef(`tool-${key({ identifier })}`);

  useEffect(() => {
    if (scriptUrl) {
      try {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.type = 'text/javascript';
        script.onload = () => {
          if (
            window[identifier] &&
            typeof window[identifier].init === 'function' &&
            currentLanguage
          ) {
            const langCode = currentLanguage.includes('-')
              ? currentLanguage.split('-')
              : [currentLanguage, currentLanguage];
            window[identifier].init({
              containerSelector: `#${toolId.current}`,
              localisationCode: `${langCode[0].toLowerCase()}-${langCode[1].toUpperCase()}`,
              mode,
            });
          }
        };
        document.head.appendChild(script);

        if (!isEmpty(styleUrl)) {
          const style = document.createElement('link');
          style.href = styleUrl;
          style.type = 'text/css';
          style.rel = 'stylesheet';
          document.head.appendChild(style);
        }
      } catch (e) {
        /* no logged errors for fastlane tools */
      }
    }
  }, [scriptUrl, currentLanguage]);

  return <div id={toolId.current} />;
}
