// types
import type { QuadraticTileProps } from './tiles.types';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Button } from 'components/Button/Button';
import { BackgroundImage } from 'components/background-image/background-image';

// utils
import { responsiveImage } from 'utils/responsiveImage';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { clickTileLink } from './tracking-actions';
import { decodingContent } from 'utils/decodingContent';
import { ToolIntegration } from 'components/ContentElements/ToolIntegration/ToolIntegration';
import { useIsMobile } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getTileLineClampClass } from 'utils/tile/utils';

function QuadraticTile({
  subheadline,
  headline,
  type,
  index,
  previewId = null,
  text = null,
  imageObject = null,
  imageObjectMobile = null,
  pictureAlt = null,
  tool = false,
  scriptUrl = null,
  styleUrl = null,
  identifier = null,
  link = null,
  colorVariant = 'white',
  from = null,
}: Readonly<QuadraticTileProps>) {
  const isMobile = useIsMobile();
  let getClassByType;
  const image = responsiveImage(isMobile, imageObjectMobile, imageObject);

  const { trackClick } = useTracking2();

  switch (type) {
    case 'quadratic_download_tile':
      getClassByType = ' c-tile-homepage__download';
      break;
    case 'quadratic_locator_tile':
      getClassByType = ' c-tile-homepage__location-service';
      break;
    default:
      getClassByType = '';
  }

  const track = useTracking();
  const onClick = () => {
    const toLink = typeof link === 'string' ? link : link.target;
    track.trackEvent(clickTileLink(window.location.href, toLink));
  };

  return (
    <div
      className={`c-tile-homepage c-tile-homepage--${colorVariant}${getClassByType}`}
      data-preview-id={from !== 'homepage' ? `#${index}` : previewId}
    >
      {tool ? (
        <div className="c-tile-content">
          <ToolIntegration
            scriptUrl={scriptUrl}
            styleUrl={styleUrl}
            identifier={identifier}
            mode="embedded-home"
          />
        </div>
      ) : (
        <CmsLink className="c-tile-content" link={link} onClick={onClick}>
          <BackgroundImage
            title={pictureAlt}
            uid={image.uid}
            // previewId={image.previewId} -> no inline editing possible if set
            format={image.format}
            format2x={image.format2x}
            format3x={image.format3x}
            retinaSrc={imageObject}
            isRemoteMedia={image.remote}
            imagePath={image.url}
            className="c-tile-content__text c-tile-content__background-image"
          >
            {subheadline && (
              <InnerHtml
                as="span"
                className={classNameBuilder('category')}
                content={subheadline}
                data-preview-id="#st_subheadline"
              />
            )}
            {headline && <InnerHtml as="h3" content={headline} data-preview-id="#st_headline" />}
            {text && <InnerHtml as="p" content={text} data-preview-id="#st_text" />}
            {type === 'quadratic_locator_tile' && (
              <form className="form-location" action="#" method="post">
                <input
                  type="text"
                  name="zipcode"
                  maxLength="5"
                  placeholder="Suche nach Postleitzahl"
                />
                <Button type="submit">
                  <span>Senden</span>
                </Button>
              </form>
            )}
            {link && (
              <span
                onClick={() =>
                  trackClick({
                    click_intent: 'more_info',
                    click_element: 'tile',
                    click_url: link.url,
                  })
                }
                className={classNameBuilder('link')}
              >
                {decodingContent(link.text)}
              </span>
            )}
          </BackgroundImage>
        </CmsLink>
      )}
    </div>
  );
}

export default QuadraticTile;
