// types
import type { ShowroomLocatorTileProps } from './tiles.types';

// styles
import styles from './locator-tile.module.scss';
import { StyledWrapper } from 'components/ContentElements/showroom-locator-teaser/showroom-locator-teaser.styles';

// components
import { BackgroundImage } from 'components/background-image/background-image';
import { ShowroomLocatorTileContent } from './showroom-locator-tile-content';
import { VideoConsent } from './video';

// utils
import { Log } from 'services/log';
import { useUCConsent } from 'features/cookie-consent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { responsiveImage } from 'utils/responsiveImage';
import { useIsMobile } from 'components/App/SizeProvider';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { SearchContextProvider } from 'components/ContentElementsGdds/locator/search';

export function ShowroomLocatorTile({
  subheadline,
  headline,
  colorVariant,
  text,
  from,
  index,
  previewId,
  imageObject,
  imageObjectMobile,
  pictureAlt,
}: Readonly<ShowroomLocatorTileProps>) {
  const consent = useUCConsent();
  const isPreview = useIsPreview();
  const locatorConfiguration = useLocatorConfiguration();
  const locatorPath = locatorConfiguration.url;
  const isMobile = useIsMobile();
  const image = responsiveImage(isMobile, imageObjectMobile, imageObject) as ImageObject;
  const orientationClass = image?.url ? 'c-tile-homepage__width-2' : '';
  const showConsent = consent.Comfort === false && locatorConfiguration !== undefined;

  if (!locatorPath) {
    if (isPreview) {
      return (
        <StyledWrapper>
          <p>locator url is missing in projectsettings!</p>
        </StyledWrapper>
      );
    }

    Log.error('locator url is missing in projectsettings!');
    return null;
  }

  return (
    <SearchContextProvider locatorPath={locatorPath}>
      <div
        className={`c-tile-homepage c-tile-homepage--${colorVariant} ${orientationClass} ${styles.locatorTile} ${styles.locatorOne}`}
      >
        <div
          data-preview-id={from !== 'homepage' ? `#${index}` : previewId}
          className={classNameBuilder('c-tile-content', styles.withOverflow)}
        >
          <div style={{ width: '100%', height: '100%' }}>
            {showConsent && <VideoConsent />}
            {!showConsent && (
              <ShowroomLocatorTileContent
                headline={headline}
                subheadline={subheadline}
                text={text}
              />
            )}
          </div>

          {image?.url && (
            <BackgroundImage
              uid={image.uid}
              previewId={image.previewId}
              format2x={image.format2x}
              format3x={image.format3x}
              format={image.format}
              isRemoteMedia={image.remote}
              imagePath={image.url}
              retinaSrc={isMobile ? imageObjectMobile : imageObject}
              title={pictureAlt}
              className="c-tile-content__image"
            />
          )}
        </div>
      </div>
    </SearchContextProvider>
  );
}
