import { useState } from 'react';
import { useSelector } from 'react-redux';

// types
import type { LocatorTileProps } from './tiles.types';

// styles
import styles from './locator-tile.module.scss';

// components
import { Button, BUTTON_TYPES } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { locatorTileSubmit } from './tracking-actions';
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { decodingContent } from 'utils/decodingContent';
import { getTileLineClampClass } from 'utils/tile/utils';

function LocatorTile({
  subheadline,
  headline,
  text,
  placeholder,
  targetUrl,
  previewId,
  colorVariant = 'blue',
  index,
  from,
}: Readonly<LocatorTileProps>) {
  const track = useTracking();
  const [locationValue, setLocationValue] = useState('');

  const colorVariantClass = `c-tile-homepage--${colorVariant}`;

  const searchPlaceholder = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_search_placeholder'),
  );
  const searchSubmitButtonLabel = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_search_submit_label'),
  );

  const onSubmit = () => {
    track.trackEvent(locatorTileSubmit(locationValue));
  };

  const onChange = (e) => {
    setLocationValue(e.currentTarget.value);
  };

  return (
    <div className={`c-tile-homepage  ${colorVariantClass} ${styles.locatorTile}`}>
      <div
        className="c-tile-content"
        data-preview-id={from !== 'homepage' ? `#${index}` : previewId}
      >
        <div className="c-tile-content__text">
          <InnerHtml as="strong" content={subheadline} previewId="#st_subheadline" />
          <InnerHtml as="h3" content={headline} previewId="#st_headline" />
          <InnerHtml as="p" content={text} previewId="#st_text" />
          <form action={targetUrl} method="GET">
            <input
              type="text"
              placeholder={decodingContent(placeholder)}
              name="location"
              onChange={onChange}
              required
              aria-label={searchPlaceholder}
            />
            <Button
              buttonType="submit"
              type={BUTTON_TYPES.PLAIN}
              symbol="search"
              onClick={onSubmit}
              aria-label={searchSubmitButtonLabel}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default LocatorTile;
