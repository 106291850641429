// types
import type { TileContentProps } from 'components/ContentElements/teaser/tiles.types';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Image } from 'components/image/image';

// utils
import { decodingContent } from 'utils/decodingContent';
import { previewId } from 'utils/preview-id';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getTileLineClampClass } from 'utils/tile/utils';
import { useTracking as useTracking2 } from 'utils/tracking/track';

function TileContent({
  subheadline = '',
  headline = '',
  link = null,
  text = '',
  journeyType = '',
  isFullwidth = '',
  type,
}: Readonly<TileContentProps>) {
  const { trackClick } = useTracking2();

  return (
    <div className="c-tile-content__text">
      {subheadline && (
        <span {...previewId('#st_subheadline')} className={classNameBuilder('category')}>
          {decodingContent(subheadline)}
        </span>
      )}
      {headline && <InnerHtml as="h3" content={headline} previewId="#st_headline" />}
      {text && <InnerHtml as="p" content={text} previewId="#st_text" />}
      {link && (
        <span
          className={classNameBuilder('link')}
          onClick={() => trackClick({ click_intent: 'more_info', click_element: 'tile' })}
        >
          {decodingContent(link.text)}
        </span>
      )}
      {journeyType && !isFullwidth && (
        <div className="marker">
          <Image src={`/icons/icon-${journeyType}.svg`} alt={journeyType} staticImage />
        </div>
      )}
    </div>
  );
}

export default TileContent;
