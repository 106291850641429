import key from 'weak-key';

// types
import type { OrientationTileProps } from './tiles.types';

// components
import LinkTile from './tile/link-tile';
import VideoTile from './tile/video-tile';

// utils
import { ToolIntegration } from 'components/ContentElements/ToolIntegration/ToolIntegration';
import { previewId } from 'utils/preview-id';

function OrientationTile(props: Readonly<OrientationTileProps>) {
  const {
    type,
    index,
    previewId: previewIdFromProps = '',
    video,
    tool = false,
    scriptUrl = '',
    styleUrl = '',
    identifier = '',
    colorVariant = 'white',
    from = '',
  } = props;
  const orientationClass = type === 'horizontal_tile' ? 'width-2' : 'height-2';
  const colorVariantClass = `c-tile-homepage--${colorVariant}`;

  if (tool) {
    return (
      <div
        {...previewId(from !== 'homepage' ? `#${index}` : previewIdFromProps)}
        key={key({ previewId: previewIdFromProps })}
        className={`c-tile-homepage c-tile-homepage__${orientationClass} ${colorVariantClass}`}
      >
        <div className="c-tile-content">
          <ToolIntegration
            scriptUrl={scriptUrl}
            styleUrl={styleUrl}
            identifier={identifier}
            mode="embedded-home"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      key={key({ previewId: previewIdFromProps })}
      className={`c-tile-homepage c-tile-homepage__${orientationClass} ${colorVariantClass}`}
      data-preview-id={from !== 'homepage' ? `#${index}` : previewIdFromProps}
    >
      {video?.videoId && video.videoId !== '' ? <VideoTile {...props} /> : <LinkTile {...props} />}
    </div>
  );
}

export default OrientationTile;
