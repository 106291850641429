import { useEffect, useState } from 'react';

// types
import type { JobsTileProps } from './tiles.types';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export default function JobsTile({ content, previewId, previewHash }: Readonly<JobsTileProps>) {
  const [count, setCount] = useState(0);

  const { trackClick } = useTracking2();

  useEffect(() => {
    const tick = () => {
      setCount(count + 1);

      if (timerId && count === parseInt(content.jobCount, 10)) {
        clearInterval(timerId);
      }
    };

    const timerId = setInterval(tick, 100);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [content.jobCount, count]);

  let headline = content.headline.replace(
    '#countries#',
    Math.min(count, Number(content.countryCount)).toString(),
  );
  headline = headline.replace('#count#', String(count));

  return (
    <div className={`c-tile-homepage c-tile-homepage--${content.colorVariant}`}>
      <CmsLink className="c-tile-content" link={content.link}>
        <EditWrapper previewId={previewId} previewHash={previewHash}>
          <div
            className="c-tile-content__text"
            style={{ backgroundImage: `url(${content.image})` }}
            role="img"
            aria-label={content.altText}
            title={content.imageTitle}
          >
            {content.subheadline && <span className="category">{content.subheadline}</span>}
            {content.headline && <InnerHtml as="h3" content={headline} />}
            {content.link && (
              <span
                className="link"
                onClick={() =>
                  trackClick({
                    click_intent: 'more_info',
                    click_element: 'tile',
                    click_url: content.link.url,
                  })
                }
              >
                {content.link.text}
              </span>
            )}
          </div>
        </EditWrapper>
      </CmsLink>
    </div>
  );
}
