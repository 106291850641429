// types
import type { LinkTileProps } from '../tiles.types';

// components
import TileContent from './tile-content';
import { CmsLink } from 'components/Link/CmsLink';
import { BackgroundImage } from 'components/background-image/background-image';
import { Image } from 'components/image/image';

// utils
import { responsiveImage } from 'utils/responsiveImage';
import { useTracking } from 'utils/hooks/useTracking';
import { useIsMobile } from 'components/App/SizeProvider';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';

function LinkTile(props) {
  const {
    backgroundImageSize,
    link,
    imageObject,
    imageObjectMobile,
    pictureAlt,
    journeyType = '',
  }: Readonly<LinkTileProps> = props;
  const isMobile = useIsMobile();
  const image: ImageObject = responsiveImage(isMobile, imageObjectMobile, imageObject);
  const isFullwidth = backgroundImageSize === 100 ? ' coverimage' : '';

  const renderTileContent = (children) => {
    if (isFullwidth) {
      return (
        <BackgroundImage
          uid={image.uid}
          // previewId={image.previewId} -> no inline editing possible if set
          format={image.format}
          format2x={image.format2x}
          format3x={image.format3x}
          isRemoteMedia={image.remote}
          imagePath={image.url}
          retinaSrc={isMobile ? imageObjectMobile : imageObject}
          title={pictureAlt}
          className="c-tile-content__image"
        >
          {children}
          {journeyType && (
            <div className="marker">
              <Image src={`/icons/icon-${journeyType}.svg`} alt={journeyType} staticImage />
            </div>
          )}
        </BackgroundImage>
      );
    }

    return children;
  };

  const track = useTracking();
  const onClick = () => {
    const toLink = typeof link === 'string' ? link : link.target;
    track.trackEvent(clickTileLink(window.location.href, toLink));
  };

  return (
    <div className="c-tile-content">
      <CmsLink className={`c-tile-content__link${isFullwidth}`} link={link} onClick={onClick}>
        {renderTileContent(<TileContent isFullwidth={isFullwidth} {...props} />)}

        {image && !isFullwidth && (
          <BackgroundImage
            uid={image.uid}
            previewId={image.previewId}
            format2x={image.format2x}
            format3x={image.format3x}
            format={image.format}
            isRemoteMedia={image.remote}
            imagePath={image.url}
            retinaSrc={isMobile ? imageObjectMobile : imageObject}
            title={pictureAlt}
            className="c-tile-content__image"
          />
        )}
      </CmsLink>
    </div>
  );
}

export default LinkTile;
